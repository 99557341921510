p {
  margin: 0.5rem 0; }

h1 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 2.5rem; }

h2 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 2rem; }

h3 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 1.75rem; }

h4 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 1.5rem; }

h5 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 1.25rem; }

h6 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 1rem; }

.main__nav-bar {
  height: 100%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 200px;
  background-color: var(--navbar-bg);
  overflow: auto;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  z-index: 2; }

.main__page {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-color: var(--page-bg);
  overflow: auto;
  position: relative;
  padding: 2rem; }

/*
The .window class creates a centered small window in the middle of 
the screen, if placed within .main or other container which centers it.
*/

.window {
  margin: 1rem;
  width: 100%;
  max-width: 480px;
  max-height: 100%;
  background: var(--bg-light);
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3); }

@media (max-width: 480px) {
    .window {
      margin: 0;
      height: 100%; } }

.window__body {
  padding: 1rem; }

.nav-bar-menu {
  width: 100%; }

.nav-bar-menu ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none; }

.nav-bar-menu a {
    display: block;
    color: var(--menu-link-color); }

.nav-bar-menu a:hover {
      color: var(--menu-active-color); }

.nav-bar-menu li {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem; }

.nav-bar-menu li.active {
      background-color: var(--menu-bg); }

.nav-bar-menu li.active a {
        color: var(--menu-active-color);
        font-weight: 700; }

.nav-bar-menu > ul > li > a {
    font-weight: 400; }

.nav-bar-menu__heading {
  font-size: 1.125rem;
  color: var(--menu-text-color);
  margin-top: 0.5rem;
  margin-bottom: 0; }

.nav-bar-menu__heading a {
    color: var(--menu-text-color); }

.nav-bar-menu__spacing {
  padding: 0.5rem 0 !important; }

.nav-bar-menu__logout:hover {
  background-color: var(--danger); }

.nav-bar-menu__logout:hover a {
    color: white; }

.nav-bar-menu__brand {
  padding: 0.5rem 0 0 1rem;
  color: var(--menu-active-color);
  font-size: 1.5rem; }

.editorial code {
  display: block;
  padding: 10px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  margin: 10px 0;
  overflow-x: auto; }

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem; }

.card {
  background-color: var(--card-bg);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  color: var(--card-color);
  padding: 1rem;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); }

.card--hover:hover {
    background-color: var(--card-hover-bg);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.3); }

.card__link {
  color: var(--card-color); }

.card__image {
  margin: 0.5rem; }

.card__label {
  margin: 0.5rem 0;
  font-size: 1.2em;
  font-weight: 700; }

.pagination {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 1.5rem; }

.pagination > * {
    display: inline-block;
    width: 40px;
    padding: 0.5rem 0;
    border: 1px solid var(--border);
    border-left-width: 0;
    text-align: center;
    background-color: var(--bg-dark);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); }

.pagination > *:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left-width: 1px; }

.pagination > *:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }

.pagination > *.active {
      font-weight: bold;
      color: var(--body-color);
      background-color: var(--bg-light); }

.pagination > a:hover {
    background-color: var(--bg-light); }

.tabs {
  width: 100%; }

.tabs__buttons {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left; }

.tabs__button {
    padding: 0.5rem 1rem;
    color: var(--body-color);
    margin-bottom: -1px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }

.tabs__button.active {
      font-weight: bold;
      background-color: var(--bg-light);
      border-color: var(--border);
      border-bottom-color: var(--bg-light);
      outline: none; }

.tabs__button:hover:not(.active) {
      background-color: var(--bg-light);
      border-color: var(--border); }

.tabs__panes {
    border: 1px solid var(--border);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); }

.tabs__pane {
    display: none;
    background-color: var(--bg-light);
    padding: 0.5rem 1rem; }

.tabs__pane.active {
      display: block; }

.documentation-method {
  font-weight: bold; }

.documentation-errors {
  padding-left: 0;
  margin: 0; }

.documentation-error {
  list-style: none; }

.documentation-error-code {
  font-weight: bold; }

.documentation-error-code::after {
  content: ':'; }

.documentation-parameters {
  padding-left: 0;
  margin: 0; }

.documentation-parameter {
  list-style: none; }

.documentation-parameter-name {
  font-weight: bold; }

.documentation-parameter-type {
  font-style: italic; }

.documentation-parameter-description {
  color: var(--faded); }

.documentation-parameter-required {
  color: var(--warning); }
